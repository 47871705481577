import { useStorage } from '@vueuse/core'
import type { AttrDestinationDto, PartnerCityListRes } from '~/server/types/city.type'

/**
 *
 * @param options 是否刷新页面
 * @returns {searchInput, searchCityList, isSearching, onConfirmCity, history, updateHistory} 返回搜索框输入值，搜索结果，是否正在搜索，确认选择城市，搜索历史，更新搜索历史
 */
export function useSearchCity(options = { refrehsOnConfirm: false }) {
  const searchInput = ref<string>('')
  const searchCityList = ref<Array<AttrDestinationDto>>([])
  const isSearching = ref<boolean>(false)

  const commonStore = useCommonStore()
  const route = useRoute()

  if (!commonStore.commonData.cityInfo?.attrDestinationList?.length) {
    commonStore.getCityList()
  }

  const history = useStorage<AttrDestinationDto[]>('AZGO_SEARCH_CITY_HISTORY', [])
  function updateHistory(item: AttrDestinationDto) {
    history.value = history.value.filter(historyItem => historyItem.destinationId !== item.destinationId)

    // Update history to only keep the last 5 items
    if (history.value.length >= 5) {
      history.value.pop()
    }
    history.value.unshift(item)
  }

  const cityInfo = computed<PartnerCityListRes>(
    () =>
      commonStore.commonData.cityInfo || {
        attrDestinationList: [],
        recommendDestinationList: [],
      },
  )

  // Enhanced search logic with debouncing for performance optimization
  watch(
    searchInput,
    debounce((value) => {
      if (!value) {
        searchCityList.value = []
        isSearching.value = false
        return
      }
      if (value.length >= 2) {
        isSearching.value = true
        searchCityList.value = performCitySearch(value)
      }
    }, 300),
  )

  function performCitySearch(searchTerm: string): AttrDestinationDto[] {
    const query = searchTerm.toLowerCase()

    // Highlight the matched characters in the search result
    const highlightRegex = new RegExp(`(${query.split('').join('|')})`, 'gi')

    return (
      sortSearch(query)?.map((item) => {
        return {
          ...item,
          destinationNameHTML:
            item.priority > 0
              ? item.destinationName?.replace(
                highlightRegex,
                match => `<span style="color: var(--tc-color-primary)">${match}</span>`,
              )
              : item.destinationName,
          countryNameHTML:
            item.priority > 0
              ? item.countryName?.replace(
                highlightRegex,
                match => `<span style="color: var(--tc-color-primary)">${match}</span>`,
              )
              : item.countryName,
        }
      }) || []
    )
  }

  function sortSearch(keyword: string) {
    // 定义正则表达式
    const startsWithRegex = new RegExp(`^${keyword}`, 'i') // 匹配以 keyword 开头的字符串
    const containsRegex = new RegExp(`${keyword}`, 'i') // 匹配包含 keyword 的字符串
    const regex = new RegExp(keyword.split('').join('.*?'), 'gi') // 匹配包含 keyword 的字符串(字母分割)

    // 过滤和排序
    return cityInfo.value.attrDestinationList
      ?.map((item) => {
        if (
          startsWithRegex.test(item.destinationName || '')
          || startsWithRegex.test(item.countryName || '')
        ) {
          return { ...item, priority: 1 } // 优先级 1
        }
        else if (
          containsRegex.test(item.destinationName || '')
          || containsRegex.test(item.countryName || '')
        ) {
          return { ...item, priority: 2 } // 优先级 2
        }
        else if (regex.test(item.destinationName || '') || regex.test(item.countryName || '')) {
          return { ...item, priority: 3 } // 优先级 3，跨字母的
        }
        return {
          ...item,
          priority: -1, // 优先级 -1，不匹配
        }
      })
      .filter(item => item.priority > 0)
      .sort((a, b) => a.priority - b.priority)
  }

  const filterConditionStore = useFilterConditionsStore()
  function onConfirmCity(item: AttrDestinationDto) {
    // Update the selected city
    filterConditionStore.filterConditions.selectedCity = item

    updateHistory(item)

    if (options.refrehsOnConfirm) {
      navigateTo({
        replace: true,
        path: `/attractions/list/${makeListRoute()}`,
        query: route.query,
      })
    }
  }

  return {
    searchInput,
    searchCityList,
    isSearching,
    onConfirmCity,
    history,
    updateHistory,
  }
}
